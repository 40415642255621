import { DEFAULT_TRANSFORMATIONS } from "@/lib/constants";
import { buildUrl, cloudinaryEnvPath } from "@/lib/useCloudinary";

import { Image, Grid } from "@/ui";

import Ads from "@/components/common/Ads";
import EmailSignUp from "@/components/common/EmailSignUp";
import NavTopics from "@/components/common/NavTopics";
import TopicsPromoTiles from "@/components/common/TopicsPromoTiles";

import BannerHowItWorks from "./BannerHowItWorks";
import Header from "./Header";
import Featured from "./Featured";
// import HelpAlert from "./HelpAlert";
import ReportsGurus from "./ReportsGurus";
// import BannerHelp from "./BannerHelp";
import BannerStartNow from "./BannerStartNow";
import QAndAPanel from "./QAndAPanel";

const videoCover = buildUrl(
  `${cloudinaryEnvPath}/misc/theme-bg-video-cover.1d31c078ed8832f4c877cf0a53e6fab2`,
  DEFAULT_TRANSFORMATIONS
); // staticUrl(require("../static/img/theme-bg-video-cover.jpg"));

import useStyles from "./styles";
import BannerAiPromo from "@/components/common/BannerAiPromo";

const Homepage = ({ homepageData }) => {
  const classes = useStyles();

  const {
    heroItinerary = {},
    featuredItineraries = [],
    guruItineraries = [],
    qAndAPanel = [],
  } = homepageData || {};

  return (
    <>
      <Image
        src={videoCover}
        alt="AtlasGuru Cover Background"
        className={classes.bgCoverImage}
        fetchPriority="low"
        loading="eager"
        height={1079}
        width={1600}
      />

      <Grid
        container
        justifyContent="center"
        className={classes.homepageContent}
      >
        <Grid item xs={12}>
          <BannerHowItWorks />
        </Grid>

        <Grid item xs={12}>
          <div className={classes.heroContainer}>
            <BannerAiPromo isHome />
          </div>
        </Grid>

        <Grid item xs={12}>
          <Header hero={heroItinerary} />
        </Grid>

        <Grid item xs={12}>
          <NavTopics
            flip
            variant="stripe"
            classes={{ root: classes.navTopics }}
          />
        </Grid>

        <Grid item xs={12}>
          <Ads
            adUnit="top"
            className={classes.ads}
            placement="section-featured-top"
            variant="billboard"
          />
        </Grid>

        {featuredItineraries && (
          <Grid item xs={12}>
            <Featured itineraries={featuredItineraries} />
          </Grid>
        )}

        <Grid item xs={12}>
          <Ads
            adUnit="mid-page"
            className={classes.ads}
            placement="section-featured-bottom"
            variant="leaderboard"
          />
        </Grid>

        <Grid item xs={12} sm={10} md={6}>
          <EmailSignUp classes={{ root: classes.emailSignUp }} />
        </Grid>

        {/*           
          <Grid item xs={12}>
            <BannerHelp />
          </Grid>
        */}

        {guruItineraries && (
          <Grid item xs={12}>
            <ReportsGurus itineraries={guruItineraries} />
          </Grid>
        )}

        <Grid item xs={12}>
          <Ads
            adUnit="bottom"
            className={classes.ads}
            placement="section-gurus-bottom"
            variant="billboard"
          />
        </Grid>

        <Grid item xs={12}>
          <BannerStartNow />
        </Grid>

        {/*
          <Grid item xs={12}>
            <HelpAlert data={tempData} />
          </Grid>
        */}

        {qAndAPanel && (
          <Grid item xs={12}>
            <QAndAPanel qAndAPanel={qAndAPanel} />
          </Grid>
        )}

        <Grid item xs={12}>
          <Ads
            adUnit="bottom"
            className={classes.ads}
            placement="section-qa-bottom"
            variant="leaderboard"
          />
        </Grid>

        <Grid item xs={12}>
          <TopicsPromoTiles
            topicsIds={[
              // TODO: filter out depends of environment and not all at the same time
              // common
              "63226d07-5711-466d-872e-0fcd2afd763a",
              // prod
              "872b85a0-4093-11ed-a1d3-0936eb4b925a",
              "a204c6b0-0c37-11ed-8840-2befd8981496",
              // dev
              "64fd1bc0-0de3-11ed-9afa-0758b4ed29b1",
              "5dd5bfa0-0de3-11ed-92d6-a57702985d12",
              // "f83be470-807f-11ed-a09a-4d6b234705ac",
              // stage
              "3710b6e0-0b68-11ed-971f-cb1cbece7ece",
              "5a6f83d0-0b6a-11ed-920d-d580018f4e1f",
            ]}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Homepage;
